// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const spareParts_en_US: ITranslations = {
  en_US: {
    "purchaseStatus.cancelled": "Cancelled",
    "purchaseStatus.collected": "Collected",
    "purchaseStatus.confirmed": "Confirmed",
    "purchaseStatus.created": "Created",
    "purchaseStatus.delivered": "Delivered",
    "purchaseStatus.draft": "Draft",
    "purchaseStatus.expired": "Expired",
    "purchaseStatus.inProcess": "In Process",
    "purchaseStatus.ordered": "Ordered",
    "purchaseStatus.partiallyCollected": "Partially collected",
    "purchaseStatus.partiallyDelivered": "Partially delivered",
    "purchaseStatus.quoted": "Quoted",
    "purchaseStatus.toBeConfirmed": "To be confirmed",
    "spareParts": "Spare parts",
    "spareParts.ContactQPNumber": "QuantiParts 24/7 phone number",
    "spareParts.DGRDelivery": "This delivery contains items that are dangerous goods.",
    "spareParts.DGROrder": "This order contains items that are dangerous goods. These items will be shipped separately from standard items. Additional costs may apply.",
    "spareParts.DGROrderLine": "This item is considered as dangerous goods and will be delivered separately from the standard items. Additional handling costs may apply.",
    "spareParts.DGRQuotation": "This quotation contains items that are dangerous goods. If ordered, these items will be shipped separately from standard items. Additional costs may apply.",
    "spareParts.DGRQuotationLine": "This item is considered as dangerous goods and if ordered, it will be delivered separately from the standard items. Additional handling costs may apply.",
    "spareParts.DGRTooltipText": "This item is considered as dangerous goods and will be delivered separately from the standard items. Additional handling costs may apply.",
    "spareParts.ItemNoteLine": "Additional information provided by Wärtsilä available.",
    "spareParts.NearestWartsilaDepartment": "your nearest Wärtsila Spare Parts Department",
    "spareParts.QPReference": "QuantiParts reference",
    "spareParts.ReasonForPurchaseOrder": "Reason for purchase order",
    "spareParts.ReasonForRequest": "Reason for request",
    "spareParts.ReasonForRequest.Breakdown": "Breakdown",
    "spareParts.ReasonForRequest.Breakdown.Description": "{attentionIcon} For immediate service and handling of your urgent request, please contact {url}",
    "spareParts.ReasonForRequest.Breakdown.Description.FeeInfo": "Please note that for orders with breakdown order reason will be processed with the highest priority and there will be a 500€, 24/7 service fee added to your final invoice",
    "spareParts.ReasonForRequest.Breakdown.Description.FeeInfoQP": ", in case the order is processed outside of office hours",
    "spareParts.ReasonForRequest.BudgetaryPurposes": "Budgetary purposes",
    "spareParts.ReasonForRequest.BudgetaryPurposes.Description": "Price and lead time awareness",
    "spareParts.ReasonForRequest.PartsForStockReplacement": "Parts for stock replacement",
    "spareParts.ReasonForRequest.PartsForStockReplacement.Description": "Safety stock / Replenishment of own stock",
    "spareParts.ReasonForRequest.ScheduledMaintenance": "Scheduled Maintenance",
    "spareParts.ReasonForRequest.ScheduledMaintenance.Description": "Planned overhaul according to maintenance schedule",
    "spareParts.ReasonForRequest.UnscheduledMaintenance": "Unscheduled Maintenance",
    "spareParts.ReasonForRequest.UnscheduledMaintenance.Description": "Unexpected need for spare parts",
    "spareParts.ReasonForRequest.expectedDate": "Expected date to receive the quotation",
    "spareParts.ReasonForRequest.expectedDateRequired": "Expected date to receive the quotation is required",
    "spareParts.ReasonForRequest.orderReason": "Order reason",
    "spareParts.ReasonForRequest.quotationReason": "Quotation reason",
    "spareParts.VAT": "VAT: {vat}",
    "spareParts.account": "Account",
    "spareParts.accountAdditionalName": "Account name (additional)",
    "spareParts.accountVATRegistration": "Account VAT number",
    "spareParts.addAdditionalInformation": "Add additional information",
    "spareParts.addDescription": "Please add description for lines: {lines}",
    "spareParts.addLineItems": "Add line items",
    "spareParts.addManually": "Add manually",
    "spareParts.addMultipleLineItems": "Add multiple line items",
    "spareParts.addSparePartsManually": "Add spare parts manually",
    "spareParts.addToBasket": "Add to basket",
    "spareParts.viewDrafts": "View drafts",
    "spareParts.viewOrders": "View orders",
    "spareParts.viewQuotes": "View quotes",
    "spareParts.addressSaveFailure": "Error saving the address. Please try agian.",
    "spareParts.addressSaveSuccess": "Address saved successfully",
    "spareParts.addressWillBeInformedLater": "Address will be informed later",
    "spareParts.agreeToTermsAndConditionsIsRequired": "You must agree with terms and conditions",
    "spareParts.agreeWith": "Agree with",
    "spareParts.allowPartiaDelivery": "Allow partial delivery",
    "spareParts.askForQuotation": "Ask for quotation",
    "spareParts.askForQuote": "Ask for quote",
    "spareParts.attachments": "Attachments",
    "spareParts.availability": "Availability",
    "spareParts.availabilitySubjectDisclamer": "Availability subject to prior sales.",
    "spareParts.basketCreatedBy": "Basket created by",
    "spareParts.basketPriceDisclaimer": "The supplier will charge the minimum invoice amount 200€ in case your total order sum is below currency equivalent to 200€.",
    "spareParts.basketWillBeDiscarded": "There are unsaved changes in the basket. If you leave this page, the basket will be emptied. Are you sure you want to continue?",
    "spareParts.blockedCustomerMessage": "Unfortunately you cannot proceed. Please contact Wärtsilä.",
    "spareParts.blockedCustomerMessageInOrder": "Cannot proceed with direct order, please ask for a quotation or contact Wärtsilä.",
    "spareParts.browseSpareParts": "Browse Spare Parts",
    "spareParts.changeInstallation": "Change installaton",
    "spareParts.checkTypingMistakes": "Please check if you have any typing mistake or click on \"I don't have a spare part number\".",
    "spareParts.chooseProduct": "Select product",
    "spareParts.classificationAttachment": "Classification",
    "spareParts.collectionDateHasChanged": "Collection date has changed",
    "spareParts.collectionLocation": "Collection location",
    "spareParts.collectionLocationForLogoOrder": "Collection location for {logo}-order",
    "spareParts.comment": "Comment",
    "spareParts.consolidationPoint": "Consolidation point",
    "spareParts.contactInformation": "Contact information",
    "spareParts.continueShopping": "Continue shopping",
    "spareParts.copy": "Copy",
    "spareParts.copyItemsPrompt": "Please copy spare part numbers into the form below",
    "spareParts.createQuote": "Create quote",
    "spareParts.createQuoteDisclaimer": "To create a pre-populated Shopping Card for {installationName}, please select the equipment you want to have spare parts for.",
    "spareParts.createdBy": "Created by",
    "spareParts.createfirstOrder": "Create your first order",
    "spareParts.creationDate": "Creation date",
    "spareParts.currency": "Currency",
    "spareParts.deleteBasketDraft": "Delete",
    "spareParts.delivery": "Delivery",
    "spareParts.delivery.collected": "Collected",
    "spareParts.delivery.delivered": "Delivered",
    "spareParts.delivery.dispatched": "Dispatched",
    "spareParts.delivery.inProcess": "In process",
    "spareParts.delivery.packed": "Packed",
    "spareParts.delivery.readyToBeCollected": "Ready to be collected",
    "spareParts.deliveryAddress": "Delivery address",
    "spareParts.deliveryAddressIsFinalDestination": "Delivery address is the final destination",
    "spareParts.deliveryDate": "ETA date",
    "spareParts.deliveryDoc.COC": "Declaration of Compliance",
    "spareParts.deliveryDoc.DGR": "DGR document",
    "spareParts.deliveryDoc.IHM": "IHM",
    "spareParts.deliveryDoc.MSDS": "MSDS",
    "spareParts.deliveryDoc.PL": "Packing list",
    "spareParts.deliveryDoc.REACH": "REACH",
    "spareParts.deliveryDoc.ROHS": "ROHS",
    "spareParts.deliveryInformation": "Delivery information",
    "spareParts.deliveryLocation": "Delivery location",
    "spareParts.deliveryLocationIsRequired": "Delivery location is required",
    "spareParts.deliveryMode": "Delivery mode",
    "spareParts.deliveryModeIsRequired": "Delivery mode is required",
    "spareParts.deliveryNote": "Delivery note",
    "spareParts.deliveryNotificationForSeparateOrders": "Wärtsilä Online will create separate orders from spare parts in your basket and orders will be delivered from different locations",
    "spareParts.deliveryStatus": "delivery",
    "spareParts.deliveryStatusLabel": "Item included in the below {delivery}",
    "spareParts.deliveryStatuses": "deliveries",
    "spareParts.deliveryTerms": "Delivery terms",
    "spareParts.deliveryTermsIsRequired": "Delivery terms is required",
    "spareParts.deliverymode.AirFreight": "Air freight",
    "spareParts.deliverymode.Courier": "Courier",
    "spareParts.deliverymode.SeaFreight": "Sea freight",
    "spareParts.deliverymode.ToBeCollected": "To be collected",
    "spareParts.deliverymode.Truck": "Truck",
    "spareParts.description": "Description",
    "spareParts.descriptionMissing": "Description is missing",
    "spareParts.discard": "Discard",
    "spareParts.dispatchDate": "Dispatch date",
    "spareParts.dispatchingInformation": "Dispatching information",
    "spareParts.distributor": "Spare part distributor",
    "spareParts.distributorInfo": "Spare part distributor contact info",
    "spareParts.doYouWantToChangeInstallation": "Do you want to change installaton?",
    "spareParts.downloadCompleteDocument": "Download complete document",
    "spareParts.draft": "Draft",
    "spareParts.draftDeleteFailure": "Error deleting the draft",
    "spareParts.draftDeleteSuccess": "Basket draft successfully deleted",
    "spareParts.draftName": "Draft name",
    "spareParts.draftNameIsRequired": "Draft name is required",
    "spareParts.draftSaveFailure": "Error saving the basket draft.",
    "spareParts.draftSaveSuccess": "Basket draft saved successfully",
    "spareParts.draftUpdateSuccess": "Basket draft updated successfully",
    "spareParts.editAdditionalInformation": "Edit additional information",
    "spareParts.editAllLines": "Edit all lines",
    "spareParts.editInformation": "Edit information",
    "spareParts.emailAddresses": "E-mail addresses",
    "spareParts.emptyBasket": "Your shopping cart is empty",
    "spareParts.errorFetchingPDF": "Error while fetching pdf details",
    "spareParts.estimatedWeight": "Estimated weight of spare parts",
    "spareParts.etaDate": "Requested ETA Date",
    "spareParts.etaDateHasChanged": "ETA date has changed",
    "spareParts.etaDateIsRequired": "ETA Date is required",
    "spareParts.etaDateIsRequiredQP": "Requested Delivery Date is required",
    "spareParts.etaDateQP": "Requested Delivery Date",
    "spareParts.export": "Export",
    "spareParts.exportAsCSV": "Export as CSV",
    "spareParts.exportAsPDF": "Export as PDF",
    "spareParts.failedFetchingManualParts": "Network error",
    "spareParts.fillRequiredFields": "Fill in the required fields for listed line items",
    "spareParts.filterPurchases": "Select product",
    "spareParts.finalDestination": "Final destination",
    "spareParts.finalDestinationIsDifferent": "Final destination address is different",
    "spareParts.forLines": "for the line(s)",
    "spareParts.freeComment": "Additional information",
    "spareParts.freeCommentPlaceholder": "E.g. special instructions for packing, requests for documentation etc.",
    "spareParts.freeCommentDescription": "For longer comments, please consider attaching a document instead.",
    "spareParts.freightCosts": "Freight costs",
    "spareParts.freightCostsOnlyAddedAtInvoicing": "Freight cost only added at invoicing",
    "spareParts.generalInformation": "General information",
    "spareParts.goodsTotal": "Goods total",
    "spareParts.handleByDistributor": "This {purchase} is handled by your QuantiParts distributor. Please contact distributor if you have questions e.g. related to {purchase}.",
    "spareParts.handledByDistributor": "This order/quote is handled by distributor",
    "spareParts.hideRecommendation": "Hide recommended products",
    "spareParts.howToDispatchOrder": "How do you want the order to be dispatched?",
    "spareParts.identificationRequired": "Technical identification required",
    "spareParts.inAccordanceWithITCode": "In accordance with art. 1341 and 1342 of the Italian Civil Code.",
    "spareParts.inStock": "IN STOCK",
    "spareParts.incompatibleParts": "Your basket contains spare parts that cannot be purchased via Wärtsilä Online. Please remove lines: {lines}",
    "spareParts.incompatibleParts.title": "Not available",
    "spareParts.incoterms": "Incoterms",
    "spareParts.informationForOrder": "Information for {logo}-order",
    "spareParts.informationForQuotation": "Information for {logo}-quote",
    "spareParts.installation": "Installation",
    "spareParts.invalidSparePartNumber": "Spare part number maximum length is 18 characters.",
    "spareParts.invalidSparePartQuantity": "Quantity must be a number.",
    "spareParts.invoice.amountCleared": "Deducted",
    "spareParts.invoice.amountOpen": "Remaining",
    "spareParts.invoice.balance": "Balance",
    "spareParts.invoice.banks": "Bank accounts",
    "spareParts.invoice.billTo": "Bill to",
    "spareParts.invoice.creditController": "Credit controller",
    "spareParts.invoice.csDocument": "Service Order number",
    "spareParts.invoice.daysUntilDueDateLessThanZero": "Invoice is overdue",
    "spareParts.invoice.daysUntilDueDateMoreThanOne": "Due in {days} days",
    "spareParts.invoice.daysUntilDueDateOne": "Due tomorrow",
    "spareParts.invoice.daysUntilDueDateZero": "Due today",
    "spareParts.invoice.dispute": "Dispute",
    "spareParts.invoice.dispute.descriptionLabel": "Detailed information",
    "spareParts.invoice.dispute.descriptionRequired": "Detailed information is required",
    "spareParts.invoice.dispute.dialogTitle": "Dispute invoice {invoiceId}",
    "spareParts.invoice.dispute.disputeRegistered": "Dispute registered",
    "spareParts.invoice.dispute.reasonLabel": "Reason for dispute",
    "spareParts.invoice.dispute.reasonRequired": "Reason for dispute is required",
    "spareParts.invoice.dispute.reminder": "Dear {customerName}, before disputing a spare part invoice, please make sure you have opened a {spc}.",
    "spareParts.invoice.dispute.requestError": "Error while sending dispute request: {error}",
    "spareParts.invoice.dispute.requestSuccess": "Dispute request sent, Wärtsilä will take needed actions.",
    "spareParts.invoice.dispute.submit": "Send",
    "spareParts.invoice.dispute.validationInProgress": "Dispute validation in progress",
    "spareParts.invoice.dueDate": "Due date",
    "spareParts.invoice.dunningLetterBody": "Please find latest dunning letter attached related to the overdue invoices on your account.",
    "spareParts.invoice.dunningLetterTitle": "Dunning letter",
    "spareParts.invoice.dunningNote": "Dunning note",
    "spareParts.invoice.dunningNote1": "This invoice appears in our formal dunning letter to you. Please arrange full settlement of the due item. In case of any queries, please contact your Credit Controller or global.creditcontrol@wartsila.com.",
    "spareParts.invoice.dunningNote2": "This invoice appears in our formal dunning letter to you. Please arrange full settlement of the due invoice to avoid possible further enforcement actions. In case of any queries, please contact your Credit Controller or global.creditcontrol@wartsila.com.",
    "spareParts.invoice.dunningNote3": "This invoice appears in our formal dunning letter to you. Please transfer the overdue amount immediately in order to minimize the severity of future enforcement actions by the Wärtsilä Group. In case of any queries, please contact your Credit Controller or global.creditcontrol@wartsila.com.",
    "spareParts.invoice.dunningNote4": "This invoice appears in our formal dunning letter to you and is seriously overdue for payment. Please arrange full settlement of the due invoice immediately to avoid legal proceedings. In case of any queries, please contact your Credit Controller or global.creditcontrol@wartsila.com.",
    "spareParts.invoice.files": "Files",
    "spareParts.invoice.filterInvoices": "Filter invoices",
    "spareParts.invoice.invoices": "Invoices",
    "spareParts.invoice.negativeBalance": "This is a credit balance in your favor.",
    "spareParts.invoice.netValue": "Amount",
    "spareParts.invoice.noInvoicesFound": "No invoices found.",
    "spareParts.invoice.notOverdue": "Not overdue",
    "spareParts.invoice.open": "Open",
    "spareParts.invoice.overdue": "Overdue",
    "spareParts.invoice.paymentTerms": "Terms and conditions",
    "spareParts.invoice.purchaseProhibition": "Purchase prohibition",
    "spareParts.invoice.referenceDocument": "Document reference",
    "spareParts.invoice.salesDocument": "Order number",
    "spareParts.invoice.status": "Status",
    "spareParts.invoice.subTotal": "Subtotal",
    "spareParts.invoice.taxAmount": "Sales tax",
    "spareParts.invoice.total": "Total",
    "spareParts.invoice.type": "Type",
    "spareParts.invoice.types.allInvoices": "All invoices",
    "spareParts.invoice.underDunning": "Under dunning",
    "spareParts.isDeliveryAddressFinalDestinationAddress": "Is the delivery address the final destination for the order?",
    "spareParts.isQuantiPartsProduct": "{icon} is {logo}-product",
    "spareParts.lastItemDialogText": "Are you sure you want to remove this item from your basket?",
    "spareParts.lastItemDialogTitle": "Removing item",
    "spareParts.lastModified": "Last modified",
    "spareParts.leadTime": "Availability",
    "spareParts.leadTimeNotificationTooltipText": "In case the spare part is required urgently, please communicate target delivery date as additional information when placing the order.",
    "spareParts.lineItems": "Line items",
    "spareParts.lineNumber": "Line number",
    "spareParts.loadBasketDraft": "Open",
    "spareParts.loadMore": "Load More",
    "spareParts.location": "Location",
    "spareParts.makeAnotherOrder": "Make another order",
    "spareParts.makeAnotherQuote": "Make another quote",
    "spareParts.markings": "Markings",
    "spareParts.markings.optional": "Markings (optional)",
    "spareParts.minimumPossibleBusinessDateLimit": "Earliest possible date can be 3 business days from now",
    "spareParts.nextStep": "Next step",
    "spareParts.noCatalogueFound": "Sorry - product catalogue is not yet available for",
    "spareParts.noDeliveries": "This item is not yet included in any deliveries",
    "spareParts.noDescription": "No description available",
    "spareParts.noFilteredPartFound": "Sorry - No spare part found for",
    "spareParts.noItemsAvailable": "No items available",
    "spareParts.noPDFinSAP": "PDF not available, please contact your Spare Part Team.",
    "spareParts.noPartsForPurchase": "This section does not contain any spare parts available for purchase",
    "spareParts.noPicturesFound": "No pictures found",
    "spareParts.noQuotationsOrOrders": "You haven't created any quotations or orders.",
    "spareParts.noQuotationsOrOrdersWhenSearched": "No documents found with given reference. Please check your reference or contact your Wärtsilä Spare Parts team for more information.",
    "spareParts.noSalesDocumentsFound": "No sales documents found",
    "spareParts.nonCancellable": "This item has to be specifically produced, and shall not be cancelled or returned.",
    "spareParts.notOfficialQuotationDisclaimer": "Not an official Quotation. Prices are subject to the global list price according to price list at the date of purchase. Availability subject to prior sales.",
    "spareParts.note": "Note",
    "spareParts.on": "On",
    "spareParts.onlyAllowCompleteOrder": "Only complete order allowed",
    "spareParts.onlyNumberOrNameRequired": "Copy paste a list of spare part numbers and the quantities you need from an excel -file.\nThe following screen will contain options to add more details or attachments related to the spare part.",
    "spareParts.order": "Order",
    "spareParts.orderAcknowledgement": "Order Acknowledgement",
    "spareParts.orderConfirmed": "Your order has now been confirmed",
    "spareParts.orderDetails": "Details for your Purchase order",
    "spareParts.orderFullDispatch": "Your order has been Fully dispatched",
    "spareParts.orderNew": "New order created",
    "spareParts.orderPartialDispatch": "Your order has been Partially dispatched",
    "spareParts.orderReceived": "Purchase order received",
    "spareParts.otherInformation": "Additional information",
    "spareParts.otherInformationNeeded": "Additional information needed",
    "spareParts.outOfVisible": "{visible} out of {total} visible",
    "spareParts.package.name": "Package name",
    "spareParts.package.ordered": "Ordered",
    "spareParts.package.packages": "Spare part packages",
    "spareParts.package.revision": "revision",
    "spareParts.page": "Page",
    "spareParts.partDescription": "Spare part description",
    "spareParts.partDescriptionIsRequired": "Spare part description is required",
    "spareParts.partNameOrNumber": "Spare part name/number",
    "spareParts.partNetWeight": "Net weight",
    "spareParts.partNotFound": "Spare part wasn't found. Please specify additional information.",
    "spareParts.partNumber": "Spare part number",
    "spareParts.partNumber.optional": "Spare part number (optional)",
    "spareParts.partOrder.pleaseNoteMayTakeUpMinutes": "It can take a few minutes for your purchase order to appear on your commerce history page.",
    "spareParts.partOrder.useReferenceNumber": "You can use the reference you have given to find your purchase order.",
    "spareParts.partOrder.weHaveReceived": "We have received your purchase order.",
    "spareParts.partOrder.weWillGetBackToYouOrder": "We will get back to you as soon as your order is processed.",
    "spareParts.partWeight": "Weight",
    "spareParts.partWeightUnit": "Weight unit",
    "spareParts.paymentTerms": "Payment terms",
    "spareParts.pictures": "Pictures",
    "spareParts.pleaseSelectProductFromList": "Please select a product from the list.",
    "spareParts.position": "Position",
    "spareParts.price": "Price",
    "spareParts.priceNoVatDisclaimer": "Prices are exclusive of VAT, and will be added based on applicable valid VAT-legislation at the time of invoicing.",
    "spareParts.pricing": "pricing",
    "spareParts.proceedToOrder": "Proceed to order",
    "spareParts.processing": "Preparing {type}...",
    "spareParts.product": "Product",
    "spareParts.productId": "Product id",
    "spareParts.productIsNotSelected": "Product is not selected",
    "spareParts.proformaDocument": "Proforma invoice",
    "spareParts.punchOutInfoDialogBody": "The following items cannot be transferred. Please contact parts.northamerica@wartsila.com to update pricing and UNSPSC for the items:",
    "spareParts.punchOutInfoDialogTitle": "Transfer cart",
    "spareParts.purchases": "History",
    "spareParts.qtySection": "Quantity in this section",
    "spareParts.quantity": "Quantity",
    "spareParts.quantityUnit": "Quantity unit",
    "spareParts.quotation": "Quotation",
    "spareParts.quotationCannotBeCopied": "Quotation with unknown spare parts cannot be copied",
    "spareParts.quotationCreated": "New quotation created",
    "spareParts.quotationQuoted": "Prices for your quotation have been confirmed",
    "spareParts.quotationReceived": "Request for quotation received",
    "spareParts.quotationRequest.pleaseNoteMayTakeUpMinutes": "It can take a few minutes for your request to appear on your commerce history page.",
    "spareParts.quotationRequest.useReferenceNumber": "You can use the reference you have given to find your request for a quotation.",
    "spareParts.quotationRequest.weHaveReceived": "We have received your request for quotation.",
    "spareParts.quotationRequest.weWillGetBackToYouQuotation": "We will get back to you as soon as your quote is processed.",
    "spareParts.quotationValidUntil": "Quotation valid until",
    "spareParts.quoteDetails": "Details for your request for quotation",
    "spareParts.readyForCollection": "Ready for collection",
    "spareParts.recommendation": "Customers who bought items in your cart also bought",
    "spareParts.recommendationBasedOn": "Proposal based on these Parts in your shopping cart: {parts}",
    "spareParts.recommendationCart": "Add to cart",
    "spareParts.referenceType": "Reference type",
    "spareParts.registerClaim": "Register Claim",
    "spareParts.relatedOrder": "Related order",
    "spareParts.relatedQuote": "Related quotation",
    "spareParts.relatedSPC": "Related spare part claim",
    "spareParts.requestPDF": "Request PDF",
    "spareParts.rows": "Rows",
    "spareParts.saveAddress": "Save address",
    "spareParts.saveInformation": "Save information",
    "spareParts.saveProgressForLater": "Do you want to save your progress for later?",
    "spareParts.selectProduct": "Select product",
    "spareParts.selectProductBeforeSearch": "Please select a product first",
    "spareParts.sendToPunchOut": "Transfer cart",
    "spareParts.sendToPunchOut.info": "In case of unconfirmed prices or availability, please request a formal quotation from your Wärtsilä office.",
    "spareParts.sendToPunchOut.requestError": "Error while transferring cart: {error}",
    "spareParts.sendToPunchOut.requestSuccess": "Cart transferred!",
    "spareParts.sentToDistributor": "Sent to distributor",
    "spareParts.serialNumber": "Serial number",
    "spareParts.serialNumber.optional": "Serial number (optional)",
    "spareParts.serviceGridButtonTitle": "Commerce",
    "spareParts.shippingAddress": "Shipping address",
    "spareParts.shoppingCart": "Shopping cart",
    "spareParts.shoppingCartEmpty": "Shopping cart empty",
    "spareParts.showOpenLinesOnly": "Show open lines only",
    "spareParts.showRecommendation": "Show recommended products",
    "spareParts.sizeAndDimensions": "Size and dimensions",
    "spareParts.sizeAndDimensions.optional": "Size and dimensions (optional)",
    "spareParts.sorting.purchaseTypeAZ": "Purchase type (A-Z)",
    "spareParts.sparePartsForProducts": "Spare parts for products",
    "spareParts.specificClauses": "Specific clauses",
    "spareParts.status": "Status",
    "spareParts.subtotal": "Subtotal",
    "spareParts.summary": "Summary",
    "spareParts.termsAndConditions": "Terms and conditions",
    "spareParts.termsConditionsDisclaimerPDF": "All sales of parts shall be subject to General Terms and Conditions – Parts (2021). In case the amount to be invoiced by the Supplier would be below an amount of currency equivalent to 200 EUR the Supplier shall charge extra and the minimum amount to be invoiced shall be 200 EUR or equivalent amount of the applicable currency added with the fee for the transportation costs if any.",
    "spareParts.termsDisclaimer": "If you have a signed Spare Parts Supply Agreement with Wärtsilä which is in force when purchasing order is made the terms and conditions of such agreement will only apply.",
    "spareParts.termsDisclaimerQP": "If you have a signed distributor agreement with QuantiParts which is in force when purchasing order is made the terms and conditions of such agreement will only apply.",
    "spareParts.thankYou": "Thank you!",
    "spareParts.thereWasErrorWhenSendingOrder": "There was an error when sending your order.",
    "spareParts.thereWasErrorWhenSendingQuotation": "There was an error when sending your quote.",
    "spareParts.toBeConfirmed": "TO BE CONFIRMED",
    "spareParts.total": "Total",
    "spareParts.totalPrice": "Total price",
    "spareParts.totalSum": "Total sum",
    "spareParts.typeInEmails": "Type in e-mail addresses",
    "spareParts.typePartNameOrNumber": "Type spare part name or number",
    "spareParts.unconfirmedPrices": "Unconfirmed prices",
    "spareParts.unconfirmedPricesFor": "Unconfirmed prices for {count} line(s)",
    "spareParts.unsavedChanges": "Unsaved changes",
    "spareParts.unspsc": "UNSPSC",
    "spareParts.usageTarget": "Usage/System/Unit",
    "spareParts.usageTarget.optional": "Usage/System/Unit (optional)",
    "spareParts.vatDisclaimer": "Prices are exclusive of VAT, and will be added based on applicable valid VAT-legislation at the time of invoicing.",
    "spareParts.vendorCode": "Vendor spare part code",
    "spareParts.vendorCode.optional": "Vendor spare part code (optional)",
    "spareParts.viewMyCommerceHistory": "View my commerce history",
    "spareParts.wartsilaReference": "Wärtsilä reference",
    "spareParts.youCanAddPartsUsingFollowingAction": "You can also add spare parts by using the following action",
    "spareParts.youCanBrowseOnlyOneInstallation": "You can browse spare parts only for one installation at the time. If you change the vessel, shopping cart you made for previous installation will be saved as a draft",
    "spareParts.yourRFQReference": "Your request for a quotation reference",
    "spareParts.yourROReference": "Your purchase order reference",
    "spareParts.yourReference": "Your reference",
    "spareParts.yourReferenceIsRequired": "Your reference is required",
    "spareParts.yourReferenceQP": "Your reference for your QuantiParts order",
    "spareParts.yourReferenceQPQuote": "Your reference for your QuantiParts quotation",
    "spareParts.yourReferenceWartsila": "Your reference for your Wärtsilä order",
    "spareParts.yourReferenceWartsilaQuote": "Your reference for your Wärtsilä quotation",
    "spareparts.errorFetchingDetails": "Error while fetching order details",
    "spareparts.package.errorFetchingItems": "Error while fetching package items",
  },
};
