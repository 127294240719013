// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const vatStatements_en_US: ITranslations = {
  en_US: {
    "vatStatement.accountID": "Account",
    "vatStatement.addAttachment": "Add attachment",
    "vatStatement.attachmentDisclaimer": "The document applies to the information given above",
    "vatStatement.company": "Company",
    "vatStatement.createdDate": "Date",
    "vatStatement.creatorName": "Creator",
    "vatStatement.customCreator": "Approver",
    "vatStatement.declarationsByCountry": "Declarations by country",
    "vatStatement.draft.editDraft": "Edit draft",
    "vatStatement.editStatement": "Edit",
    "vatStatement.editStatement.contactWartsila": "If you want to remove vessel or country, contact Wärtsilä",
    "vatStatement.editStatement.vesselRemovingAlert": "You can add vessels and countries. Removing is not possible.",
    "vatStatement.export": "Export",
    "vatStatement.fileUploadLimit": "Maximum upload file size: 25MB",
    "vatStatement.filterVatStatements": "Filter VAT Statements",
    "vatStatement.imoNr": "IMO: {imoNr}",
    "vatStatement.infoText": "If you want to buy from {organization} without being charged with VAT as ship supply please provide us with the appropriate statement. In order to identify the right statement please consider where you request {organization} to deliver the goods. If, for example, you request to deliver the goods within The Netherlands you shall provide us with the Dutch statement but if, for some purchases, you want to have the goods delivered to Germany please provide us also with the German statement. Same applies for other countries. We thank you for your cooperation and in case of any questions please contact us at:",
    "vatStatement.newVATStatement": "New VAT statement",
    "vatStatement.newVatStatement.BE": "Belgium",
    "vatStatement.newVatStatement.BE.text": "We confirm that the above vessels fulfil all the conditions provided for the VAT exemption by art. 42, § 1, alinea 1, 1° of the Belgian VAT Code and namely that each vessel :\n• has navigated in international waters for at least 70% of the total navigation or time;\n• is used only to perform a business activity. \nWe confirm that all the parts and the equipment we order are of a kind ordinarily installed or incorporated in, and are to be installed or incorporated in, the propulsion, navigation or communications systems or the general structure of a qualifying vessel as set out in the EU VAT Directive Art.148.\nWe undertake to advice you immediately should these parts be used for any other purpose and pay you the VAT due.",
    "vatStatement.newVatStatement.DE": "Germany",
    "vatStatement.newVatStatement.DE.text": "We hereby declare that all parts we order are for equipment of a seagoing vessel and are for the direct need of one of those vessels. They are of a kind ordinarily installed or incorporated in, and are to be installed or incorporated in, the propulsion, navigation or communications systems or the general structure of a qualifying sea-going vessel as set out in the EU VAT Directive Art. 148 and according to Sec. 4 number 2 in conjunction with Sec. 8 Para. 1 of the German VAT Act.\nWe undertake to advice you immediately should these parts be used for any other purpose and pay you the VAT due.",
    "vatStatement.newVatStatement.ES": "Spain",
    "vatStatement.newVatStatement.ES.text": "We confirm that the above vessels fulfil all the conditions provided for the\nVAT exemption by art.22, Spanish VAT Act and namely that each vessel :\n• has navigated in international waters in 2020 for at least 50% of the total navigation or time;\n• is used only to perform a business activity. \nWe confirm that all the parts and the equipment we order are of a kind ordinarily installed or incorporated in, \nand are to be installed or incorporated in, the propulsion, navigation or communications systems or the general\nstructure of a qualifying vessel as set out in the EU VAT Directive Art.148.\nWe undertake to advice you immediately should these parts be used for any other purpose and pay you the VAT due.",
    "vatStatement.newVatStatement.FR": "France",
    "vatStatement.newVatStatement.FR.text": "The undersigned declares that the vessels meet all conditions for the application of the VAT exemption as provided by art.262, par. 2°, 3°, 6° et 7° of the General Tax code (CGI) and namely that the vessel :\n• is commercially registered;\n• is equipped with a permanent crew;\n• is assigned to a commercial activity;\n• navigate at least for 70% of its total navigation in international waters.\nThe non-respect of any of the above conditions implies the change of destination of the vessel in accordance with art.257, par.2(III) of the CGI and therefore it will lead to charge you VAT later on.",
    "vatStatement.newVatStatement.GB": "UK",
    "vatStatement.newVatStatement.GB.text": "We confirm that all the parts and equipment we order or in this purchase order are of a kind ordinarily installed or incorporated in, and are to be installed or incorporated in, the propulsion, navigation or communications systems or the general structure of a qualifying ship as set out in Notice 744C.\nWe undertake to advice you immediately should the services and/or parts be used for any other purpose and pay you the VAT due.",
    "vatStatement.newVatStatement.GR": "Greece",
    "vatStatement.newVatStatement.GR.text": "Certificate of compliance with the conditions applicable to VAT exemptions on operations by certain vessels stipulated in art.27 of Law 2859/2000 of the Greek VAT law \nI, the undersigned {customerName} on behalf of the company {companyName}, certify that the vessels fulfil all the conditions provided by the Greek VAT law to apply the VAT exemption for ship supply as provided by art. 27 of Law 2859/2000 that is to say: \n• it has an upper external length at least equal to 12 meters; \n• it operates mostly on the high seas; \n• it is classified under one of the following tariff class codes of the EU Common Customs Tariff \n(89011010-89012010-89013010-89019010-89020010-89039110-89039210-89040091-89069010).\nI also confirm that : \n• any changes to the above-mentioned conditions constitutes a change of use of the vessel and we will immediately inform you;\n• in case the Greek tax authorities will issue (retroactive) guidance providing that additional/other procedural steps \nand/or documents should be put in place we will provide you with such additional documentation, otherwise you will be \nentitled to charge VAT where appropriate \nFailure to comply with the above duty of information implies that we will be charged retroactively with VAT, unless a different VAT exemption may apply to the sale.",
    "vatStatement.newVatStatement.NL": "Netherlands",
    "vatStatement.newVatStatement.NL.text": "We confirm that the above vessels fulfil all the conditions provided for the VAT exemption by Table II, item a3 and a4, of the Dutch VAT Act and namely that each vessel:\n• has navigated for more than 70% of the total navigation or time of previous year in international waters;\n• is used to perform solely commercial activity in accordance with art.148, EU VAT Directive.\nWe confirm that all the parts and the equipment we order are of a kind ordinarily installed or incorporated in, and are to be installed or incorporated in, the propulsion, navigation or communications systems or the general structure of a qualifying vessel as set out in the EU VAT Directive Art.148.\nWe undertake to advice you immediately should these parts be used for any other purpose and/or if any relevant information contained in this statement be subject to changes and pay you the VAT eventually due.",
    "vatStatement.newVatStatement.PL": "Poland",
    "vatStatement.newVatStatement.PL.text": "We confirm that all the goods and services we order are serving directly for provisioning ship meeting conditions indicated in the art. 83 par. 1 sub. 10 / art. 83 par. 1 sub. 15, art. 83 par 1. sub. 17 of the Polish VAT Act (Act of 11 March 2004; uniform text published in the Journal of Laws June, 24 2017 No. 1221).We undertake to advise you immediately should these goods be used for any other purpose and pay you the VAT due.",
    "vatStatement.newVatStatement.SE": "Sweden",
    "vatStatement.newVatStatement.SE.text": "We confirm that the above vessels fulfil all the conditions provided for the VAT exemption by Chapter 3, art.21, Swedish VAT Act and namely that each vessel :\n• has navigated in international waters for at least 50% of the total navigation or time in previous year;\n• is used only to perform a business activity. \nWe confirm that all the parts and the equipment we order are of a kind ordinarily installed or incorporated in, and are to be installed or incorporated in, the propulsion, navigation or communications systems or the general structure of a qualifying vessel as set out in the EU VAT Directive Art.148.\nWe undertake to advice you immediately should these parts be used for any other purpose and pay you the VAT due.",
    "vatStatement.newVatStatement.alreadyExistingInstallationInfoText": "This installation is already chosen in earlier VAT-statement created from this account.\nYou can not unselect submitted countries. We highly suggest to edit already existing statement for this vessel,\nnot make a new one.",
    "vatStatement.newVatStatement.draftRequestSuccess": "Draft of Your VAT statement saved",
    "vatStatement.newVatStatement.phases.accounts": "Select account",
    "vatStatement.newVatStatement.phases.countries": "Select countries",
    "vatStatement.newVatStatement.phases.countriesPhase.selectAll": "Select all countries",
    "vatStatement.newVatStatement.phases.countriesPhase.unselectAll": "Unselect all countries",
    "vatStatement.newVatStatement.phases.summary": "Attachment(s) and Summary",
    "vatStatement.newVatStatement.phases.summaryPhase.account": "Account",
    "vatStatement.newVatStatement.phases.summaryPhase.addNewCreator": "Add new approver",
    "vatStatement.newVatStatement.phases.summaryPhase.checkTheName": "Check that approver's name and job title are correct",
    "vatStatement.newVatStatement.phases.summaryPhase.creator": "Approver of VAT statement",
    "vatStatement.newVatStatement.phases.summaryPhase.fullNameRequired": "Full name required",
    "vatStatement.newVatStatement.phases.summaryPhase.jobTitleRequired": "Job title required",
    "vatStatement.newVatStatement.phases.summaryPhase.sameAsSubmitter": "Same as submitter",
    "vatStatement.newVatStatement.phases.summaryPhase.submitter": "Submitter of VAT statement",
    "vatStatement.newVatStatement.phases.summaryPhase.yearOfStatement": "Year of validity",
    "vatStatement.newVatStatement.phases.vesselPhase.infoForAddingVessel": "In case a vessel is missing from the list please provide us with its IMO number and name and we will add to\nit. The inclusion of the vessel in the fleet list takes usually 1 or 2 working days",
    "vatStatement.newVatStatement.phases.vessels": "Select vessels",
    "vatStatement.newVatStatement.phases.vesselsPhase.addMissingVessel": "Add missing vessel",
    "vatStatement.newVatStatement.phases.vesselsPhase.selectAll": "Select all vessels",
    "vatStatement.newVatStatement.phases.vesselsPhase.unselectAll": "Unselect all vessels",
    "vatStatement.newVatStatement.phases.vesselsPhase.vesselMissing": "Vessel missing?",
    "vatStatement.newVatStatement.requestError": "Error while sending VAT statement: {error}",
    "vatStatement.newVatStatement.requestSuccess": "VAT statement sent!",
    "vatStatement.newVatStatement.validation.accountRequired": "At least one account is required",
    "vatStatement.newVatStatement.validation.allBoxesCheckRequired": "You should check all boxes and therefore agree with all countries rules",
    "vatStatement.newVatStatement.validation.countryRequired": "At least one country per vessel required",
    "vatStatement.newVatStatement.validation.vesselRequired": "At least one vessel is required",
    "vatStatement.noStatementsAvailable": "There are no available VAT statements",
    "vatStatement.nrOfVessels": "Number of vessels",
    "vatStatement.ok": "ok",
    "vatStatement.pageNumber": "Page {pageNumber} ({totalPages})",
    "vatStatement.preparedBy": "VAT statement approved by",
    "vatStatement.status": "Status",
    "vatStatement.vatStatementForYear": "VAT Statement for {year}",
    "vatStatement.vatStatements": "VAT Statements",
    "vatStatement.vesselsAndVATCountries": "Vessels & VAT countries",
    "vatStatement.yearOfStatement": "Year",
  },
};
